import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../shared/global-constants';
import { Router } from '@angular/router';
import { ApplicationRoutes } from '../ApplicationRoutes';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  dashboardUrl: string = environment.dashboardUrl;
  journeyMgmtUrl: string = environment.journeyMgmtUrl;
  informationTrainingUrl: string = environment.informationTrainingUrl;
  headerImg = GlobalConstants.imgUrl;
  tile1Img = GlobalConstants.tile1Url;
  tile2Img = GlobalConstants.tile2Url;
  tile3Img = GlobalConstants.tile3Url;
  tile4Img = GlobalConstants.tile4Url;
  constructor(private router: Router) { }

  ngOnInit(): void {
  // This is intentional
 }
  // Opens SRPT in new tab
  openSrpt(): void{
    const url = this.router.createUrlTree([ApplicationRoutes.SRPT]);
    window.open(url.toString());
  }
   // Opens powerBI dashboard in new tab
  openDashboard(): void{
    window.open(this.dashboardUrl);
  }
  // Opens Journey management(MVS site until) in new tab
  openJourneymgmt(): void{
    window.open(this.journeyMgmtUrl);
  }
  // Opens Information and Training in new tab
  openInformationTraining(): void {
    window.open(this.informationTrainingUrl);
  }
}
