// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseApiUrl: 'https://rsa-test-cvx.azurewebsites.net/api/',
    pythonApiUrl: 'https://rsa-py-test-cvx.azurewebsites.net/api/',
    emailToggle: false,
    dashboardUrl: 'https://app.powerbi.com/groups/me/apps/fc9c48e3-07a5-4c91-bb7b-3c921c3a0e7c/reports/ae00e06c-5c58-4fee-a3aa-cc341a033353/ReportSection?ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
  journeyMgmtUrl: 'https://mcbu.chevron.com/oe/operations/mvs.aspx',
  informationTrainingUrl: 'https://chevron.sharepoint.com/sites/MCBURSA'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */

