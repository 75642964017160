export class GlobalConstants{
    // Default values for cutom coordinates
    public static originLon = -102.9713;
    public static originLat = 31.9648;
    public static destinationLon = -102.1093;
    public static destinationLat = 31.87555;
    public static stopLon = -102.1113;
    public static stopLat = 31.9348;

    // Main image url
    public static imgUrl = '\\..\\..\\assets\\chevron_logo.png';
    public static tile1Url = '\\..\\..\\assets\\tile1.png';
    public static tile2Url = '\\..\\..\\assets\\tile2.png';
    public static tile3Url = '\\..\\..\\assets\\tile3.png';
    public static tile4Url = '\\..\\..\\assets\\title4.png';

    // configuration for sliders
    public static minVal = 8;
    public static maxVal = 17;
    public static tickStep = 1;
    public static tickStepVal = 3;
    public static floorVal = 0;
    public static ceilVal = 23;


   // Date picker configuration
    public static calPlaceholder = 'Choose a date...';
    public static calDateformat = 'YYYY-MM-DD';
    public static calWeekdayformat = 'dd';

    // High Risk map email and Route selection email
    public static highRiskMapEmailSubject = 'High Risk Area Map';
    public static highRiskMapEmailBody = 'https://drive.google.com/open?id=1h26_NV-TDV9yPGQneCqB-ZTvRder6FXA&usp=sharing';
    public static loading = 'Loading...';
    public static routeSelectionEmailSubject = 'Selected Route';
    public static bottom: any = 'bottom';
    public static right: any = 'right';
    public static routeGoogleDir = 'https://www.google.com/maps/dir';
    public static highriskFileName = 'high_risk_map';
    public static emailRes = 'StatusText : Success';
    public static emailSuccess = 'Email sent successfully';
    public static emailFailure = 'Email delivery failed';
    public static successStatus = 'success';
    public static failureStatus = 'failure';
    public static defaultStatus = 'default';
    public static isHighRiskSpot = 'HighRiskSpot';
    public static addRouteEmailTooltip = 'Please make sure you\'ve clicked Add Route option before sending email';
    public static domainName = 'chevron.com';

    // Error messages
    public static errorMessage = 'Some error occurred while processing your request.';
    public static googleApiErrorMessage = 'Something went wrong. Please check inputs.';

    // to support all timezones
    public static timeUTC = 'T00:00';
    // snackbar fade timer
    public static fadeTimer = 2000;
    public static fadeEmailTimer = 3000;

    // common strings
    public static commaSeperator = ',';
    public static pipeSeperator = '|';
    public static slashSeperator = '/';
    public static blankData = '';
    public static plusSeperator = '+';
    public static atSeperator = '@';
    public static stopFrequency  = 40;
    public static checkZero = 0;
    public static checkIndex = -1;
    public static checkLength = 1;
    public static rootNavigation = '/srpt';
    public static emailLimit = 20;

    // Strings for preformatted and email text in route navigation and related to leaflet map
    public static formatNextLine = '\r\n';
    public static space = ' ';
    public static dottedLines = '----------------------';
    public static colon = ' :';
    public static colonSpace = ': ';
    public static hyphen = ' - ';
    public static routeAddString = 'Route ';
    public static routeString = 'ROUTE ';
    public static fromString = 'FROM: ';
    public static toString = 'TO: ';
    public static stopNames = 'STOPS IN SEQUENCE:';
    public static minString = ' min.';
    public static riskString = 'Estimated risk of route ';
    public static timeString = 'Estimated time of route ';
    public static mileString = 'Estimated miles route ';
    public static emailAdd = ' added to the email';
    public static breakLine = '<br>';
    public static div = 'div';
    public static infoLegend = 'info legend';
    public static infoMarkerLegend = 'info-marker legen';
    public static iconBackground = '<i style=\'background:';
    public static iconImageWidth = '<img width=\'24px\' src=';
    public static iconEnd = '\'></i> ';
    public static iconImageEnd = '></i> ';
    public static startHref = '<href>';
    public static endHref = '</href>';
    public static hyphenBreakLine = ' - <br/>';
    public static maxZoom = 19;
    public static defaultZoom = 7;
    public static iconAnchor: any = [15, 42];
    public static iconWaypointAnchor: any = [12, 42];
    public static originIconSize: any = [30, 42];
    public static stopIconSize: any = [32, 44];
    public static destinationIconSize: any = [30, 32];
    public static waypointIconSize: any = [30, 42];
    public static map = 'map';
    public static cssIcon = 'custom-div-icon';
    public static cssStopIcon = 'stop-div-icon';
    public static originIcon = '<div style=\'background-color:#27AE60;\' class=\'marker-pin\'></div><i class=\'fa fa-home fa-4x\'></i>';
    public static destinationIcon = '<div style=\'background-color:#E31A1C;\' class=\'marker-pin\'></div><i class=\'fa fa-flag fa-lg\'></i>';
    public static waypointStartIcon = '<div style=\'background-color:#2F32B0;\' class=\'marker-pin\'></div><i class=\'fa-stack-1x\'>';
    public static waypointEndIcon = '</i>';
    public static leafletTile = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    public static leafletLink = '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    public static blackCircleIconUrl = '\\..\\..\\assets\\circle-icon.png';
    public static blackCircleIconSize = 14;
    public static highRiskMapCenterLat = 31.901;
    public static highRiskMapCenterLon = -102.91;
    public static redTriangleIconUrl = '\\..\\..\\assets\\triangle-icon.png';
    public static redTriangleIconSize = 10;
    public static mapRoutesCenterLat = 31.901;
    public static mapRoutesCenterLon = -102.91;
    public static routeLegendPosition = 'topright';
    public static markerLegendPosition = 'bottomleft';
    public static origin = 'Origin';
    public static destination = 'Destination';
    public static stop = 'Stop ';
    public static stops = 'Stops - ';
    public static originIconUrl = '\\..\\..\\assets\\origin-icon.png';
    public static destinationIconUrl = '\\..\\..\\assets\\destination-icon.png';
    public static clusterIconUrl = '\\..\\..\\assets\\cluster-icon.png';
    public static waypointIconUrl = '\\..\\..\\assets\\waypoint-icon.png';
    public static stopIdentityIconUrl = '\\..\\..\\assets\\stop-identity-icon.png';
    public static redTriangleLegendInfo = 'Five or more incidents';
    public static blackCircleLegendInfo = 'Top one percentile by <p class="legend-multiline-align">frequency and severity</p>';
    public static waypointLegendInfo = '(+) Multiple Stop,<p class="legend-multiline-align">(1-6) Single Stop</p>';

    // All API Const goes here..
    public static highRiskAreaApi = 'GetHighRiskAreaMap';
    public static getLocationsApi = 'GetLocations';
    public static getRoutesDataApi = 'GetMapRoutes';
    public static getOptimalRoutesApi = 'GetBestOptimalRouteData';
    public static sendEmailApi = 'SendEmail';
    public static sendEmailViaSMTPApi = 'SendEmailViaSmtp';
    public static getRiskPredictionApi = 'GetRiskEnhancePredictionData';

}
